import React from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import {
  Button, FormField, SidebarNoticeBox,
} from '@campaignhub/suit-theme'

const ResourcePrompts = (props) => {
  const { callbacks, resourceTypes, serviceJob, uploadState } = props

  const { allowResourceUpload, updateUploadCount } = callbacks
  const { allowUpload, doneUpload, filesToUploadCount, recentlyFailed, recentlyUploaded, selectedResourceType, uploadResourceType } = uploadState

  const entities = useSelector(reduxState => reduxState.entities)
  const { resources } = entities

  let ambientCountError = false
  const currentAmbientCount = Object.values(resources).filter(x => x.resourceTypeId === resourceTypes.find(type => type.name === 'Ambient')?.id).length

  const checkResourceType = uploadResourceType ? parseInt(uploadResourceType, 10) : selectedResourceType
  if (recentlyUploaded.length > 0 && checkResourceType === resourceTypes.find(x => x.name === 'Ambient')?.id && serviceJob?.totalAssets){
    ambientCountError = currentAmbientCount < serviceJob.totalAssets * 5
  }

  return (
    <>
      {!doneUpload && !(recentlyUploaded.length > 0 || recentlyFailed.length > 0) && (
        <SidebarNoticeBox boxProps={{ marginBottom: 'medium' }}>
          <SidebarNoticeBox.Title>Number of Resources</SidebarNoticeBox.Title>

          <SidebarNoticeBox.Paragraph>
            How many files will you be uploading?
          </SidebarNoticeBox.Paragraph>
          <FormField direction="column" marginTop="medium">
            <input
              type="number"
              onChange={e => updateUploadCount(e.target.value)}
              value={filesToUploadCount > 0 ? filesToUploadCount : ''}
              disabled={allowUpload}
            />
          </FormField>
          <Button
            buttonStyle="primaryCreate"
            size="medium"
            marginTop="medium"
            onClick={() => {
              if (parseInt(filesToUploadCount, 10) > 0){
                allowResourceUpload()
              }
            }}
            disabled={allowUpload}
          >
            Okay
          </Button>
        </SidebarNoticeBox>
      )}

      {(recentlyUploaded.length > 0 || recentlyFailed.length > 0) && (
        <>
          <SidebarNoticeBox boxProps={{ marginBottom: ambientCountError ? 'large' : 'medium' }}>
            <SidebarNoticeBox.Title>Resources</SidebarNoticeBox.Title>

            <SidebarNoticeBox.Paragraph>
              {recentlyUploaded.length} out of {filesToUploadCount} images uploaded.
            </SidebarNoticeBox.Paragraph>
          </SidebarNoticeBox>

          {ambientCountError && (
            <SidebarNoticeBox boxProps={{ marginBottom: 'large' }}>
              <SidebarNoticeBox.Title>
                {currentAmbientCount} / {serviceJob?.totalAssets * 5} Required Ambient Resources
              </SidebarNoticeBox.Title>
              <SidebarNoticeBox.Paragraph>
                {`It seems like the shooting formula was not followed, are you sure you have uploaded the right amount of exposures?\n
              In the event you did not shoot to the formula this will effect editing and potential turnaround time.\n
              Please make sure moving forward the shooting formula is followed.`}
              </SidebarNoticeBox.Paragraph>
            </SidebarNoticeBox>
          )}

          {recentlyUploaded.length !== parseInt(filesToUploadCount, 10) && (
            <SidebarNoticeBox
              boxProps={{
                marginBottom: 'medium',
                backgroundColor: 'lightcyan!important',
                borderColor: 'rgb(125, 208, 215, 0.5)!important',
              }}
            >
              <SidebarNoticeBox.Title>Resources</SidebarNoticeBox.Title>

              <SidebarNoticeBox.Paragraph>
                Your resource QTY does not match the total number of uploaded resources. Please note that you can always upload or delete resources from your end.
              </SidebarNoticeBox.Paragraph>
            </SidebarNoticeBox>
          )}
        </>
      )}
    </>
  )
}

ResourcePrompts.propTypes = {
  callbacks: PropTypes.object.isRequired,
  resourceTypes: PropTypes.array,
  serviceJob: PropTypes.object,
  uploadState: PropTypes.object,
}

export default ResourcePrompts
